<template>
  <address-info-card :address="inv.billingAddress"/>
  <div class="row mb-1">
    <div class="col-lg-12">
      <label class="form-label">Způsob fakturace</label>
      <input type="text" v-model="inv.description" class="form-control form-control-lg" readonly/>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-lg-6">
      <label class="form-label">Platební metoda</label>
      <input type="text" v-model="inv.paymentMethod" class="form-control form-control-lg" readonly/>
    </div>
    <div class="col-lg-6" v-if="inv.sipoId">
      <label class="form-label">SIPO ID</label>
      <input type="text" v-model="inv.sipoId" class="form-control form-control-lg" readonly/>
    </div>
  </div>
</template>

<script>
import AddressInfoCard from "@/components/AddressInfoCard";
export default {
  name: "InvoicingCard",
  components: {AddressInfoCard},
  props: {
    invoicing: {
      type: Object,
      requires: true,
    },
  },
  data() {
    return {
      inv: this.invoicing,
    }
  },
}
</script>

<style scoped>

</style>