<template>
  <div class="container mb-2" v-if="notices.length > 0">
    <button type="button" class="btn btn-outline-danger fs-5" data-bs-toggle="collapse"
            data-bs-target="#notices" aria-expanded="false" aria-controls="notices">
      <i class="bi bi-exclamation-triangle-fill"></i>
      Důležitá upozornění, klikněte pro zobrazení
    </button>
    <div class="collapse mt-2" id="notices">
      <div v-for="notice in notices" :key="notice.id" class="alert alert-danger d-flex align-items-center p-2" role="alert">
        <i class="bi bi-exclamation-triangle-fill fs-4"></i>
        <span class="ms-2">{{ notice }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notices",
  props: {
    notices: Array,
  },
}
</script>

<style scoped>
</style>