<template>
  <div class="row mb-1">
    <div class="col-lg-6">
      <label class="form-label">Jméno / Společnost</label>
      <input type="text" v-model="addr.fullName" class="form-control form-control-lg" readonly/>
    </div>
  </div>
  <div class="row mb-1">
    <div class="col-lg-12">
      <label class="form-label">Adresa</label>
      <input type="text" v-model="addr.fullAddress" class="form-control form-control-lg" readonly/>
    </div>
  </div>
  <div class="row mb-1" v-if="addr.ic || addr.dic">
    <div class="col-lg-6" v-if="addr.ic">
      <label class="form-label">IČ</label>
      <input type="text" v-model="addr.ic" class="form-control form-control-lg" readonly/>
    </div>
    <div class="col-lg-6" v-if="addr.dic">
      <label class="form-label">DIČ</label>
      <input type="text" v-model="addr.dic" class="form-control form-control-lg" readonly/>
    </div>
  </div>
  <div class="row mb-1" v-if="addr.emails.length > 0 || addr.phones.length > 0">
    <div class="col-12 col-lg-6" v-if="addr.emails.length > 0">
      <label class="form-label">Email</label>
      <div v-for="email in addr.emails" :key="email.id">
        <input type="text" :value="email" class="form-control form-control-lg" readonly/>
      </div>
    </div>
    <div class="col-lg-6" v-if="addr.phones.length > 0">
      <label class="form-label">Telefon</label>
      <div v-for="phone in addr.phones" :key="phone.id">
        <input type="text" :value="phone" class="form-control form-control-lg" readonly/>
      </div>
    </div>
  </div>
</template>

<script>
import {formatPhone} from '@/helpers';

export default {
  name: "AddressInfoCard",
  props: {
    address: {
      type: Object,
      requires: true,
    },
  },
  computed: {
    addr() {
      let address = this.address;
      address.phones = address.phones.map(phone => {
        return formatPhone(phone);
      });
      return address;
    }
  }
}
</script>

<style scoped>

</style>