<template>
  <section class="login mb-5">
    <div class="container narrow-container">
      <h1 class="text-primary" v-if="isEmailReadonly">Nastavení nového hesla pro účet</h1>
      <h1 class="text-primary" v-else>Zapomenuté heslo</h1>

      <p v-if="!isEmailReadonly" class="m-0">
        Zadejte prosím Váš přihlašovací e-mail. Na tuto adresu bude zaslán odkaz pro obnovu hesla.
      </p>

      <flash-message :message="flashMessage.data"></flash-message>

      <form class="mt-3" @submit.prevent="sendResetPasswordEmail" v-if="formVisible">
        <div class="form-floating mb-3">
          <input id="loginEmail" type="email" placeholder="Uživatelské jméno" v-model="email"
                 class="form-control" :readonly="isEmailReadonly" required/>
          <label for="loginEmail" class="text-muted">Emailová adresa účtu</label>
        </div>
        <button type="submit" class="btn btn-lg btn-success">Odeslat autorizační kód</button>
      </form>
    </div>
  </section>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import {gfcSendResetPasswordEmail} from "@/firebase";

export default {
  components: {FlashMessage},
  inject: ["globalSpinner"],
  data() {
    return {
      formVisible: true,
      email: '',
      emailReadonly: false,
      flashMessage: {
        data: "",
      },
    }
  },
  created() {
    if (this.$route.query.email !== undefined) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    sendResetPasswordEmail() {
      this.flashMessage.data = {};
      this.globalSpinner.active = true;
      gfcSendResetPasswordEmail(this.email).then((response) => {
        if (response.data.error !== undefined) {
          this.flashMessage.data = response.data.error;
        } else {
          this.flashMessage.data = {
            success: true,
            message: "Na e-mail " + this.email + "  jsme Vám zaslali odkaz pro nastavení nového hesla. Dejte prosím elektronické poště několik minut na doručení. Platnost odkazu je jedna hodina.",
          };
        }
        this.formVisible = false;
      }).catch((err) => {
        this.flashMessage.data = err;
      }).finally(() => this.globalSpinner.active = false);
    }
  },
  computed: {
    isEmailReadonly() {
      return this.$route.query.email !== undefined;
    }
  }
}
</script>

<style scoped>
</style>
