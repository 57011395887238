import { createStore } from 'vuex'

export default createStore({
  state: {
    authorized: false,
    username: null,
  },
  mutations: {
    setAuthorization (state, value) {
      state.authorized = value
    },
    setUsername (state, value) {
      state.username = value
    },
  },
  actions: {
  },
  modules: {
  }
})
