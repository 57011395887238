<template>
  <section class="login mb-5">
    <div class="container narrow-container">
      <h1 class="text-success">Heslo bylo úspěšně nastaveno</h1>

      <div>
        <div class="alert alert-success p-2">Nyní jste již přihlášen(a) do samoobsluhy, přejděte do ní tlačítkem níže.</div>
        <router-link class="btn btn-lg btn-primary" to="/">Přejít se do samoobsluhy</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped>
</style>
