<template>
  <div class="row pb-2 gy-2 gx-3 align-items-center bg-light">
    <div class="col-auto">
      <div class="form-floating">
        <input type="text" class="form-control" id="filterDocNum" placeholder="Číslo dokladu" v-model="filterDocNum">
        <label class="text-muted" for="filterDocNum">Číslo dokladu</label>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="form-floating">
        <select class="form-select" id="filterType" v-model="filterType">
          <option selected value="">Všechny typy dokladů</option>
          <option>Faktura</option>
          <option>Dobropis</option>
          <option>Oprava</option>
        </select>
        <label class="text-muted" for="filterType">Typ dokladu</label>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-floating">
        <input type="date" class="form-control" id="filterFrom" placeholder="Od" v-model="filterFrom">
        <label class="text-muted" for="filterFrom">Od</label>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-floating">
        <input type="date" class="form-control" id="filterTo" placeholder="Do" v-model="filterTo">
        <label class="text-muted" for="filterTo">Do</label>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="filterUnpaid" v-model="filterUnpaid">
        <label class="form-check-label" for="filterUnpaid">
          Zobrazit pouze nezaplacené doklady
        </label>
      </div>
    </div>
  </div>
  <table class="table table-hover table-borderless text-primary invoices-table">
    <thead>
      <tr>
        <th>Detail</th>
        <th>Číslo dokladu (VS)</th>
        <th>Typ dokladu</th>
        <th>Datum vystavení</th>
        <th>Datum splatnosti</th>
        <th>Částka (vč. DPH)</th>
        <th>Nedoplatek</th>
        <th>Způsob úhrady</th>
        <th>Doklad</th>
        <th>Stav úhrady</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(invoice, idx) in filteredInvoices" :key="`invoice-${idx}`">
        <tr class="invoice-row">
          <td class="py-2">
            <button class="btn text-primary py-0 btn-outline-none" @click="toggleItems(invoice.docNum)">
              <i v-if="!opened.includes(invoice.docNum)" class="bi bi-plus-circle fs-3"></i>
              <i v-if="opened.includes(invoice.docNum)" class="bi bi-dash-circle fs-3"></i>
            </button>
          </td>
          <td>{{ invoice.docNum }}</td>
          <td>{{ invoice.docType }}</td>
          <td>{{ invoice.expositionDateFormatted }}</td>
          <td>{{ invoice.maturityDateFormatted }}</td>
          <td>{{ invoice.sumFormatted }}</td>
          <td>{{ invoice.liquidationFormatted }}</td>
          <td>{{ invoice.paymentMethod }}</td>
          <td>
            <a :href="'stahnout-doklad/' + invoice.docNum" v-if="invoice.hasDocument"
               @click.prevent="downloadPdf(invoice.docNum)">Stáhnout PDF</a>
          </td>
          <td :class="invoice.paymentStatusClass">{{ invoice.paymentStatus }}</td>
        </tr>
        <tr v-if="opened.includes(invoice.docNum)">
          <td colspan="10" class="pt-0">
            <table class="table table-sm m-0">
              <thead>
                <tr>
                  <th>Položka</th>
                  <th>Částka (vč. DPH)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in invoice.items" :key="item.id">
                  <td>{{ item.description }}</td>
                  <td>{{ item.priceFormatted }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import {formatDate, formatMoney, parseDateFromString} from '@/helpers';

export default {
  name: "InvoicesTable",
  inject: ["loadDocumentPdf"],
  props: {
    invoices: Array,
  },
  data() {
    return {
      opened: [],
      filterDocNum: "",
      filterType: "",
      filterFrom: null,
      filterTo: null,
      filterUnpaid: false,
    }
  },
  methods: {
    downloadPdf(docNum) {
      this.loadDocumentPdf({"docNum": docNum, "docType": "invoice"}, docNum + '.pdf');
    },
    toggleItems(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
  },
  computed: {
    filteredInvoices() {
      const filteredInvoices = this.invoices.filter(i => {
        const expositionDate = new Date(i.expositionDate);

        // filtr dle cisla dokladu
        if (this.filterDocNum !== "" && i.docNum.toString().indexOf(this.filterDocNum) < 0) {
          return false;
        }

        // typ dokladu
        if (this.filterType !== "" && i.docType !== this.filterType) {
          return false;
        }

        // filtr datumu platby od
        const filterFromDate = parseDateFromString(this.filterFrom);
        if (filterFromDate !== null && filterFromDate > expositionDate) {
          return false;
        }

        // filtr datumu platby do
        const filterToDate = parseDateFromString(this.filterTo);
        if (filterToDate !== null && filterToDate < expositionDate) {
          return false;
        }

        // neuhrazene faktury
        if (this.filterUnpaid && i.paymentStatus === "Uhrazeno") {
          return false;
        }

        return true;
      });

      return filteredInvoices.map(invoice => {
        invoice.expositionDateFormatted = formatDate(invoice.expositionDate);
        invoice.maturityDateFormatted = formatDate(invoice.maturityDate);
        invoice.sumFormatted = formatMoney(invoice.sum);
        invoice.liquidationFormatted = formatMoney(invoice.sum - invoice.paidToDate);
        invoice.paymentStatusClass = "fw-bold";
        if (invoice.paymentStatusContext !== "") {
          invoice.paymentStatusClass += " text-" + invoice.paymentStatusContext;
        }

        invoice.items = invoice.items.map(item => {
          item.priceFormatted = formatMoney(item.price);
          return item;
        });

        return invoice;
      })
    },
  },
}
</script>

<style scoped>
  table.invoices-table {
    font-size: .9rem;
    border-collapse: separate;
    border-spacing: 0 1rem;
  }

  table td, table th {
    font-weight: normal;
    padding: .1rem .8rem;
  }

  table th {
    color: #6b6b6b;
  }

  tr.invoice-row > td {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 1.6rem .8rem;
  }

  tr.invoice-row > td:first-child {
    border-left: 1px solid #d0d0d0;
    -moz-border-radius-topleft: .6rem;
    -webkit-border-top-left-radius: .6rem;
    border-top-left-radius: .6rem;
    -moz-border-radius-bottomleft: .6rem;
    -webkit-border-bottom-left-radius: .6rem;
    border-bottom-left-radius: .6rem;
  }

  tr.invoice-row > td:last-child {
    border-right: 1px solid #d0d0d0;
    -moz-border-radius-topright: .6rem;
    -webkit-border-top-right-radius: .6rem;
    border-top-right-radius: .6rem;
    -moz-border-radius-bottomright: .6rem;
    -webkit-border-bottom-right-radius: .6rem;
    border-bottom-right-radius: .6rem;
  }
</style>