<template>
  <section class="account">
    <div class="container">
      <h2 class="text-primary mb-3">Zákaznický účet</h2>

      <h4 class="card-title text-primary fw-bold mt-4">Vaše přihlašovací údaje do Samoobsluhy</h4>
      <div class="row mb-3">
        <div class="col-lg-6">
          <label for="loginEmail" class="form-label text-muted">Uživatelské jméno</label>
          <input id="loginEmail" type="text" placeholder="Uživatelské jméno" v-model="user.email" class="form-control form-control-lg" readonly/>
        </div>
      </div>

      <div v-if="customer">
        <div class="card border-success bg-success rounded-5 my-4 account__info-card">
          <div class="card-body">
            <h4 class="card-title text-primary fw-bold">Vaše osobní údaje</h4>
            <div class="row mb-1">
              <div class="col-lg-6">
                <label for="customerName" class="form-label">Jméno / Společnost</label>
                <input id="customerName" type="text" v-model="customer.fullName" class="form-control form-control-lg" readonly/>
              </div>
              <div class="col-lg-6">
                <label for="customerId" class="form-label">Zákaznické číslo</label>
                <input id="customerId" type="text" v-model="customer.uid" class="form-control form-control-lg" readonly/>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-lg-6">
                <label for="customerVs" class="form-label">Trvalý variabilní symbol</label>
                <input id="customerVs" type="text" v-model="customer.vs" class="form-control form-control-lg" readonly/>
              </div>
            </div>

            <div class="row mb-1" v-if="customer.rc || customer.birthDate">
              <div class="col-lg-6" v-if="customer.rc">
                <label for="customerRc" class="form-label">Rodné číslo</label>
                <input id="customerRc" type="text" v-model="customer.rc" class="form-control form-control-lg" readonly/>
              </div>
              <div class="col-lg-6" v-if="customer.birthDate">
                <label for="customerBirthDate" class="form-label">Datum narození</label>
                <input id="customerBirthDate" type="text" v-model="customer.birthDate" class="form-control form-control-lg" readonly/>
              </div>
            </div>
          </div>
        </div>

        <div class="card border-success bg-success rounded-5 my-4 account__info-card">
          <div class="card-body">
            <div class="d-md-flex mb-2 mb-md-0">
              <h4 class="card-title text-primary fw-bold flex-grow-1">Vaše kontaktní údaje</h4>
              <a class="btn btn-lg btn-primary d-block" href="https://www.nordictelecom.cz/aktualizaceudaju" target="_blank">
                Aktualizovat údaje
              </a>
            </div>
            <div v-for="contactAddress in customer.contactAddresses" :key="contactAddress.id">
              <address-info-card :address="contactAddress"/>
            </div>
          </div>
        </div>

        <div class="card border-success bg-success rounded-5 my-4 account__info-card">
          <div class="card-body">
            <h4 class="card-title text-primary fw-bold">Vaše fakturační údaje</h4>
            <div v-for="invoicing in customer.invoicings" :key="invoicing.id">
              <invoicing-card :invoicing="invoicing"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {auth} from '@/firebase';
import AddressInfoCard from "@/components/AddressInfoCard";
import {formatDate} from "@/helpers";
import InvoicingCard from "@/components/InvoicingCard";

export default {
  components: {InvoicingCard, AddressInfoCard},
  inject: ["globalCustomer", "loadCustomerSnapshot"],
  data() {
    return {
      user: {},
    }
  },
  created() {
    this.user = auth.currentUser;
  },
  computed: {
    customer() {
      const customer = this.globalCustomer.data;
      if (customer === null) {
        this.loadCustomerSnapshot();
        return null;
      }

      if (customer.birthDate) {
        customer.birthDate = formatDate(customer.birthDate);
      }
      return customer;
    },
  }
}
</script>