<template>
  <section class="contracts">
    <div class="container">
      <h2 class="text-primary mb-3">Dokumenty</h2>
      <documents-table :documents="documents" v-if="documents.length > 0"/>
      <div v-else class="alert alert-info">Žádné dokumenty nejsou k dispozici</div>
    </div>
  </section>
</template>

<script>
import DocumentsTable from "@/components/DocumentsTable";

export default {
  components: {DocumentsTable},
  inject: ["globalCustomer", "loadCustomerSnapshot"],
  computed: {
    documents() {
      if (this.globalCustomer.data === null) {
        this.loadCustomerSnapshot();
        return [];
      }

      return this.globalCustomer.data.contracts;
    }
  }
}
</script>
