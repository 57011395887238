<template>
  <beat-loader :color="'#3c1053'" size="20px" class="p-1" v-if="show"></beat-loader>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

export default {
  name: "Spinner",
  components: {BeatLoader},
  props: {
    show: {
      required: false,
      type: Boolean,
      default: false,
    },
    text: {
      required: false,
      type: String,
      default: "Načítání",
    }
  }
}
</script>

<style scoped>
</style>