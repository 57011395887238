<template>
  <section class="login mb-5">
    <div class="container narrow-container">
      <h1 class="text-primary">{{ title }}</h1>
      <flash-message :message="flashMessage.data"></flash-message>

      <p>
        Z důvodu bezpečnosti si prosím <strong>zvolte heslo</strong>, které obsahuje <strong>minimálně 9 znaků</strong>,
        z toho alespoň <strong>1 velké písmeno</strong>, <strong>1 malé písmeno</strong> a <strong>1 číslici</strong>.
      </p>

      <form class="mt-3" @submit.prevent="resetPassword" v-if="formVisible">
        <div class="form-floating mb-3">
          <input id="newPassword" :type="passwordType" placeholder="Nové heslo" v-model="newPassword"
                 class="form-control" autocomplete="off"/>
          <label for="newPassword" class="text-muted">Vaše nové heslo</label>
          <div class="toggle-password-icon">
            <i :class="eyeClass" class="bi" @click="togglePassword" title="Zobrazit heslo"></i>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input id="newPasswordConfirm" :type="passwordType" placeholder="Potvrzení hesla" v-model="newPasswordConfirm"
                 class="form-control" autocomplete="off"/>
          <label for="newPasswordConfirm" class="text-muted">Potvrzení hesla</label>
          <div class="toggle-password-icon">
            <i :class="eyeClass" class="bi" @click="togglePassword" title="Zobrazit heslo"></i>
          </div>
        </div>
        <button type="submit" class="btn btn-lg btn-success">Nastavit</button>
      </form>
      <div v-else>
        <router-link class="btn btn-lg btn-primary" to="/reset-hesla">Zaslat nový email pro nastavení hesla
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import {auth} from '@/firebase';
import FlashMessage from "@/components/FlashMessage";
import {loadCustomerData, validatePassword} from "@/helpers";
import firebase from "firebase/app";

export default {
  components: {FlashMessage},
  inject: ["globalSpinner"],
  data() {
    return {
      mode: null,
      actionCode: null,
      formVisible: false,
      newPassword: '',
      newPasswordConfirm: '',
      passwordType: 'password',
      flashMessage: {
        data: "",
      },
    }
  },
  created() {
    this.mode = this.$route.query.mode;
    this.actionCode = this.$route.query.oobCode;

    switch (this.mode) {
      case "resetPassword":
        // zobrazeni formulare pro reset hesla
        auth.verifyPasswordResetCode(this.actionCode).then(() => {
          this.formVisible = true;
        }).catch((error) => {
          this.flashMessage.data = error;
        })
        break;
      case "verifyEmail":
        auth.applyActionCode(this.actionCode).then(() => {
          this.$router.push("/reset-hesla");
        }).catch((error) => {
          this.flashMessage.data = error;
        });
        break;
      default:
        this.flashMessage.data = "Neplatná akce";
    }
  },
  computed: {
    title() {
      switch (this.mode) {
        case "resetPassword":
          return "Nastavení hesla";
        case "verifyEmail":
          return "Potvrzení aktivace účtu";
      }

      return "Neznámá akce";
    },
    eyeClass() {
      return this.passwordType === "password" ? "bi-eye-slash" : "bi-eye";
    }
  },
  methods: {
    resetPassword() {
      const newPassword = this.newPassword;
      this.flashMessage.data = {};

      const validation = validatePassword(newPassword, this.newPasswordConfirm);
      if (validation.error) {
        this.flashMessage.data = validation.error;
        return;
      }

      this.newPassword = '';
      this.newPasswordConfirm = '';
      this.globalSpinner.active = true;
      auth.verifyPasswordResetCode(this.actionCode).then((accountEmail) => {
        auth.confirmPasswordReset(this.actionCode, newPassword).then(() => {
          auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() =>
              auth.signInWithEmailAndPassword(accountEmail, newPassword).then((userData) =>
                  loadCustomerData(userData.user.uid)
                      .then(() => console.debug("Data uložena"))
                      .catch((err) => this.globalFlashMessage.data = err)
                      .finally(() => this.globalSpinner.active = false)
              )
          );
          this.$router.push("/heslo-ulozeno");
        })
            .catch(err => this.flashMessage.data = err)
            .finally(() => this.globalSpinner.active = false);
      })
          .catch(err => this.flashMessage.data = err)
          .finally(() => this.globalSpinner.active = false);
    },
    togglePassword() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    }
  }
}
</script>

<style scoped>

</style>
