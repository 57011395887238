import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import moment from 'moment';
import IdleVue from 'idle-vue-3'
import {createApp} from 'vue'
import eventHub from './eventHub'

moment.locale("cs");

createApp(App)
    .use(store)
    .use(router)
    .use(IdleVue, {
      eventEmitter: eventHub,
      store: store,
      idleTime: 1200000, // autologout po 20 minutach necinnosti
    })
    .mount('#app');