<template>
  <section class="billing">
    <div class="container">
      <h2 class="text-primary mb-3">Vyúčtování</h2>
      <invoices-table :invoices="invoices" v-if="invoices.length > 0"/>
      <div v-else class="alert alert-info">Žádné vyúčtování není k dispozici</div>
    </div>
  </section>
</template>

<script>
import InvoicesTable from "@/components/InvoicesTable";

export default {
  components: {InvoicesTable},
  inject: ["globalCustomer", "loadCustomerSnapshot"],
  computed: {
    invoices() {
      if (this.globalCustomer.data === null) {
        this.loadCustomerSnapshot();
        return [];
      }

      return this.globalCustomer.data.invoices;
    }
  }
}
</script>
