<template>
  <section class="welcome">
    <div class="container">
      <h2 class="mb-3 d-lg-none">Vítejte!</h2>
      <h2 class="mb-3 d-none d-lg-block">Vítejte v samoobsluze telekomunikační rodiny Nordic Telecom</h2>

      <template v-if="accountBalance">
        <div class="alert alert-info p-2 fw-bold" v-if="accountBalance.unpaidBeforeMaturity > 0">
          <div class="row">
            <div class="col-md-5 align-self-center text-center text-md-start">Čekáme na úhradu, Vaše vyúčtování je před splatností.</div>
            <div class="col-md-3 align-self-center text-center text-md-end">{{ accountBalance.unpaidBeforeMaturityFormatted }}</div>
            <div class="col-md-4 align-self-center text-center text-md-end">
              <router-link class="btn btn-outline-primary" to="/vyuctovani">Přejít na detail vyúčtování</router-link>
            </div>
          </div>
        </div>

        <div class="alert alert-warning p-2 fw-bold" v-if="accountBalance.debt > 0">
          <div class="row">
            <div class="col-md-5 align-self-center text-center text-md-start">Prosíme o úhradu, Vaše vyúčtování je již po splatnosti.</div>
            <div class="col-md-3 align-self-center text-center text-md-end">{{ accountBalance.debtFormatted }}</div>
            <div class="col-md-4 align-self-center text-center text-md-end">
              <router-link class="btn btn-outline-primary" to="/vyuctovani">Přejít na detail vyúčtování</router-link>
            </div>
          </div>
        </div>

        <div class="alert alert-success p-2 fw-bold" v-if="accountBalance.unpaidSum <= 0">
          <div class="row">
            <div class="col-md-6 align-self-center text-center text-md-start">Děkujeme, máte vše uhrazeno.</div>
            <div class="col-md-6 align-self-center text-center text-md-end">
              <router-link class="btn btn-outline-primary" to="/vyuctovani">Přejít na detail vyúčtování</router-link>
            </div>
          </div>
        </div>

        <div class="alert alert-secondary p-2 fw-bold" v-if="accountBalance.unpairedPaymentsSum > 0">
          <div class="row">
            <div class="col-md-5 align-self-center text-center text-md-start">Vaše přijaté, dosud nepřiřazené platby.</div>
            <div class="col-md-3 align-self-center text-center text-md-end">{{ accountBalance.unpairedPaymentsSumFormatted }}</div>
            <div class="col-md-4 align-self-center text-center text-md-end">
              <router-link class="btn btn-outline-primary" to="/platby">Přejít na detail plateb</router-link>
            </div>
          </div>
        </div>

        <hr>

        <div class="alert alert-light p-2 fw-bold">
          <div class="row">
            <div class="col-md-5 align-self-center text-center text-md-start">Platební rekapitulace (-přeplatek/+nedoplatek)</div>
            <div class="col-md-3 align-self-center text-center text-md-end">{{ accountBalance.balanceFormatted }}</div>
            <div class="col-md-4 align-self-center text-center text-md-end">
              <router-link class="btn btn-outline-primary" to="/platby">Přejít na detail plateb</router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import {formatMoney} from '@/helpers';

export default {
  inject: ["globalCustomer", "loadCustomerSnapshot"],
  computed: {
    accountBalance() {
      if (this.globalCustomer.data === null) {
        this.loadCustomerSnapshot();
        return null;
      }

      if (this.globalCustomer.data.accountBalance === undefined) {
        return null;
      }

      const accountBalance = this.globalCustomer.data.accountBalance;
      accountBalance.unpaidBeforeMaturity = accountBalance.unpaidSum - accountBalance.debt;
      accountBalance.unpaidBeforeMaturityFormatted = formatMoney(accountBalance.unpaidBeforeMaturity);
      accountBalance.debtFormatted = formatMoney(accountBalance.debt);
      accountBalance.balanceFormatted = formatMoney(accountBalance.balance);
      accountBalance.unpairedPaymentsSumFormatted = formatMoney(accountBalance.unpairedPaymentsSum);
      return accountBalance;
    }
  }
}
</script>

<style scoped>

</style>
