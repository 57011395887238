<template>
  <table class="table table-hover table-borderless text-primary invoices-table">
    <thead>
    <tr>
      <th>Číslo smlouvy</th>
      <th>Typ smlouvy</th>
      <th>Stav</th>
      <th>Platná od</th>
      <th>Platná do</th>
      <th>Datum podpisu</th>
      <th>Dokument</th>
    </tr>
    </thead>
    <tbody>
    <template v-for="contract in contractsRendered" :key="contract.docId">
      <tr class="invoice-row">
        <td>{{ contract.docNum }}</td>
        <td>{{ contract.type }}</td>
        <td>{{ contract.status }}</td>
        <td>{{ contract.validFromFormatted }}</td>
        <td>{{ contract.validToFormatted }}</td>
        <td>{{ contract.signedAtFormatted }}</td>
        <td>
          <a :href="'stahnout-doklad'" v-if="contract.hasDocument"
             @click.prevent="downloadPdf(contract)">Stáhnout PDF</a>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
import {formatDate} from '@/helpers';

export default {
  name: "DocumentsTable",
  inject: ["loadDocumentPdf"],
  props: {
    documents: [],
  },
  methods: {
    downloadPdf(contract) {
      this.loadDocumentPdf(
          {"docId": contract.docId, "docType": contract.isLegacy ? "legacy_contract" : "contract"},
          contract.type + '.pdf'
      );
    },
  },
  computed: {
    contractsRendered() {
      return this.documents.map(contract => {
        contract.signedAtFormatted = formatDate(contract.signedAt);
        contract.validFromFormatted = formatDate(contract.validFrom);
        contract.validToFormatted = contract.validTo ? formatDate(contract.validTo) : "-";
        return contract;
      });
    },
  },
}
</script>

<style scoped>
  table {
    font-size: .9rem;
    border-collapse: separate;
    border-spacing: 0 1rem;
  }

  table td {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 1.6rem .8rem;
  }

  table th {
    font-weight: normal;
    color: #6b6b6b;
    padding: 0 .8rem;
  }

  table td:first-child {
    border-left: 1px solid #d0d0d0;
    border-radius: .6rem 0 0 .6rem;
  }

  table td:last-child {
    border-right: 1px solid #d0d0d0;
    border-radius: 0 .6rem .6rem 0;
  }
</style>