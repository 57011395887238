<template>
  <section class="payments">
    <div class="container">
      <h2 class="text-primary mb-3">Vaše přijaté platby</h2>
      <payments-table :payments="payments" v-if="payments.length > 0"/>
      <div v-else class="alert alert-info">Žádné platby nejsou k dispozici</div>
    </div>
  </section>
</template>

<script>
import PaymentsTable from "@/components/PaymentsTable";

export default {
  components: {PaymentsTable},
  inject: ["globalCustomer", "loadCustomerSnapshot"],
  computed: {
    payments() {
      if (this.globalCustomer.data === null) {
        this.loadCustomerSnapshot();
        return [];
      }

      return this.globalCustomer.data.payments;
    }
  }
}
</script>
