<template>
  <section class="registration mb-5">
    <div class="container narrow-container">
      <h1 class="text-primary">Registrace</h1>
      <flash-message :message="flashMessage.data"></flash-message>

      <form @submit.prevent="register" v-if="formVisible">
        <div class="form-check form-switch mb-2 text-start">
          <input class="form-check-input" type="checkbox" role="switch" id="businessSubjectSwitch" v-model="businessSubject">
          <label class="form-check-label" for="businessSubjectSwitch">Mám u vás vedeny služby na IČ</label>
        </div>

        <div class="form-floating mb-2" v-if="businessSubject">
          <input id="regCustomerIc" type="text" placeholder="IČ" v-model="customerIc"
                 v-bind:class="{'form-control':true, 'is-invalid' : invalidField('customerIc')}"/>
          <label for="regCustomerIc" class="text-muted">IČ</label>
        </div>

        <div class="row align-items-center">
          <div class="col-lg-5">
            <div class="form-floating mb-2">
              <input id="regCustomerId" type="text" placeholder="ID zákazníka" v-model="customerId"
                     v-bind:class="{'form-control':true, 'is-invalid' : invalidField('customerId')}"/>
              <label for="regCustomerId" class="text-muted">Zákaznické číslo</label>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="mb-2">nebo</div>
          </div>

          <div class="col-lg-5">
            <div class="form-floating mb-2">
              <input id="regCustomerVs" type="text" placeholder="Trvalý VS" v-model="customerVs"
                     v-bind:class="{'form-control':true, 'is-invalid' : invalidField('customerVs')}"/>
              <label for="regCustomerVs" class="text-muted">Trvalý VS</label>
            </div>
          </div>
        </div>

        <div class="form-floating mb-2">
          <input id="regCustomerName" type="text" placeholder="Jméno" v-model="customerName"
                 v-bind:class="{'form-control':true, 'is-invalid' : invalidField('customerName')}"/>
          <label for="regCustomerName" class="text-muted">Jméno</label>
        </div>

        <div class="form-floating mb-2">
          <input id="regCustomerSurname" type="text" placeholder="Příjmení" v-model="customerSurname"
                 v-bind:class="{'form-control':true, 'is-invalid' : invalidField('customerSurname')}"/>
          <label for="regCustomerSurname" class="text-muted">Příjmení</label>
        </div>

        <div class="form-floating mb-2">
          <input id="regCustomerCompany" type="text" placeholder="Název společnosti" v-model="customerCompany" v-if="businessSubject"
                 v-bind:class="{'form-control':true, 'is-invalid' : invalidField('customerCompany')}"/>
          <label for="regCustomerCompany" class="text-muted">Název společnosti</label>
        </div>

        <div class="form-floating mb-2">
          <input id="regInvoice" type="text" placeholder="Číslo dokladu posledního vyúčtování" v-model="invoice"
                 v-bind:class="{'form-control':true, 'is-invalid' : invalidField('invoice')}"/>
          <label for="regInvoice" class="text-muted">Var. symbol posledního vyúčtování</label>
        </div>

        <div class="form-floating mb-2">
          <input id="regEmail" type="text" placeholder="Emailová adresa" v-model="email" autocomplete="off"
                 v-bind:class="{'form-control':true, 'is-invalid' : invalidField('email')}"/>
          <label for="regEmail" class="text-muted">Emailová adresa</label>
          <div id="regEmailHelp" class="form-text text-start">Můžete zvolit email, na který vám zasíláme vyúčtování nebo jinou emailovou adresu.</div>
        </div>

        <button type="submit" class="btn btn-lg btn-success mb-2" :disabled="submiting">
          Vytvořit účet
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submiting"></span>
        </button>
        <p>Máte již účet?
          <router-link to="/prihlaseni">Přihlašte se zde</router-link>
        </p>
      </form>
    </div>
  </section>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import {isEmailValid} from "@/helpers";
import {gfcRegister} from '@/firebase'

export default {
  components: {FlashMessage},
  inject: ["globalSpinner"],
  data() {
    return {
      flashMessage: {
        data: "",
      },
      invalid: [],
      submiting: false,
      formVisible: true,
      businessSubject: false,
      email: "",
      customerId: "",
      customerVs: "",
      customerIc: "",
      customerName: "",
      customerSurname: "",
      customerCompany: "",
      invoice: "",
    }
  },
  methods: {
    invalidField(field) {
      return this.invalid.includes(field);
    },
    valid() {
      this.invalid = [];

      if (this.businessSubject && (this.customerIc.length === 0 || isNaN(this.customerIc))) {
        this.invalid.push("customerIc");
      }

      if (this.customerId.trim() === "" && this.customerVs.trim() === "") {
        this.invalid.push("customerId");
        this.invalid.push("customerVs");
      } else {
        if (isNaN(this.customerId)) {
          this.invalid.push("customerId");
        }

        if (isNaN(this.customerVs)) {
          this.invalid.push("customerVs");
        }
      }

      if(!isEmailValid(this.email)) {
        this.invalid.push("email");
      }

      if ((this.customerName.trim() === "" || this.customerSurname.trim() === "") && this.customerCompany.trim() === "") {
        this.invalid.push("customerName");
        this.invalid.push("customerSurname");
        this.invalid.push("customerCompany");
      }

      if (this.invoice.trim() === "" || isNaN(this.invoice)) {
        this.invalid.push("invoice");
      }

      return (this.invalid.length === 0);
    },
    register() {
      this.submiting = true;
      if (this.valid()) {
        const formData = {
          "email": this.email,
          "id": this.customerId,
          "vs": this.customerVs,
          "name": this.customerName,
          "surname": this.customerSurname,
          "company": this.customerCompany,
          "invoice": this.invoice,
        };

        if (this.businessSubject) {
          formData.ic = this.customerIc;
        }

        this.globalSpinner.active = true;
        this.flashMessage.data = {};
        gfcRegister(formData)
            .then(() => {
              this.flashMessage.data = {
                success: true,
                message: "Účet byl úspěšně vytvořen. Zkontrolujte prosím vaši emailovou schránku, kam vám bude zaslán odkaz pro nastavení hesla.",
              };
              this.formVisible = false;
            })
            .catch(err => this.flashMessage.data = err)
            .finally(() => {
              this.globalSpinner.active = false;
              this.submiting = false;
            });
      } else {
        this.submiting = false;
      }
    }
  }
}
</script>

<style scoped>

</style>