<template>
  <div class="container narrow-container" v-if="messageContent.length > 0">
    <div class="alert p-2" :class="messageClassObject">{{ messageContent }}</div>
  </div>
</template>

<script>
export default {
  props: {
    message: {}
  },
  data() {
    return {
      // TODO: Tohle by chtelo nekam vyseparovat jako konfiguraci hlasek
      errorMessages: new Map([
        [
          "auth/expired-action-code",
          "Autorizační kód již vypršel. Vygenerujte nový odkaz opětovným odesláním formuláře pro obnovu hesla."
        ],
        [
          "auth/invalid-action-code",
          "Neplatný autorizační kód. Vygenerujte nový odkaz opětovným odesláním formuláře pro obnovu hesla."
        ],
        [
          "auth/user-disabled",
          "Uživatel má neaktivní účet."
        ],
        [
          "auth/user-not-found",
          "Klient s tímto e-mailem nebyl nalezen."
        ],
        [
          "auth/weak-password",
          "Příliš slabé heslo."
        ],
        [
          "auth/wrong-password",
          "Neplatné heslo nebo heslo ještě není nastaveno."
        ],
        [
          "auth/email-already-in-use",
          "Emailová adresa je již použita u jiného účtu, zvolte prosím jinou."
        ],
        [
          "auth/invalid-email",
          "Neplatná emailová adresa."
        ],
        [
          "auth/operation-not-allowed",
          "Operace není povolena."
        ],
        [
          "app/reset-pass-error",
          "Nepodařilo se vygenerovat autorizační email, kontaktujte prosím podporu."
        ],
      ]),
    }
  },
  computed: {
    messageClassObject() {
      if (this.message.success === true) {
        return {"alert-success": true};
      }
      return {"alert-danger": true};
    },
    messageContent() {
      let msg = this.message;

      if (this.message.errorInfo !== undefined) {
        msg = this.message.errorInfo;
      }

      if (msg.code !== undefined && this.errorMessages.has(msg.code)) {
        return this.errorMessages.get(msg.code);
      }

      if (msg.message !== undefined) {
        return msg.message;
      }

      return msg;
    }
  }
}
</script>

<style scoped>
</style>