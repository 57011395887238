<template>
  <section class="login mb-5">
    <div class="container narrow-container">
      <h1 class="text-primary">Přihlášení</h1>
      <flash-message :message="flashMessage.data"></flash-message>

      <form class="mt-4" @submit.prevent="login">
        <div class="form-floating">
          <input id="loginEmail" type="text" placeholder="Uživatelské jméno" v-model="email" class="form-control"/>
          <label for="loginEmail" class="text-muted">Uživatelské jméno</label>
        </div>
        <div class="form-floating mt-3 position-relative">
          <input id="loginPassword" :type="passwordType" placeholder="Heslo" v-model="password" class="form-control"
                 autocomplete="off"/>
          <label for="loginPassword" class="text-muted">Heslo</label>
          <div class="toggle-password-icon">
            <i :class="eyeClass" class="bi" @click="togglePassword" title="Zobrazit heslo"></i>
          </div>
        </div>

        <div id="forgotPasswordHelp" class="form-text mt-1">
          <router-link to="/reset-hesla">Zapomněli jste heslo?</router-link>
          <span class="mx-1">|</span>
          <router-link to="/registrace">Zaregistrujte se</router-link>
        </div>

        <button type="submit" class="btn btn-lg btn-success mt-2">Přihlásit</button>
      </form>
    </div>
  </section>
</template>

<script>
import {auth} from '@/firebase';
import FlashMessage from "@/components/FlashMessage";
import firebase from "firebase/app";
import {loadCustomerData} from "@/helpers";

export default {
  components: {FlashMessage},
  inject: ["globalSpinner", "globalFlashMessage", "globalCustomer"],
  data() {
    return {
      email: '',
      password: '',
      passwordType: 'password',
      flashMessage: {
        data: "",
      },
    }
  },
  computed: {
    eyeClass() {
      return this.passwordType === "password" ? "bi-eye-slash" : "bi-eye";
    }
  },
  methods: {
    login() {
      this.globalFlashMessage.data = "";
      this.globalSpinner.active = true;
      this.flashMessage.data = {};
      auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
        auth.signInWithEmailAndPassword(this.email, this.password).then((userData) => {
          loadCustomerData(userData.user.uid)
              .then((customer) => {
                this.globalCustomer.data = customer
              })
              .catch(err => this.globalFlashMessage.data = err)
              .finally(() => this.globalSpinner.active = false);
        }
        ).catch(err => {
          this.flashMessage.data = err;
          this.globalSpinner.active = false;
        });
        this.email = '';
        this.password = '';
      });
    },
    togglePassword() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    }
  },
}
</script>

<style scoped>
</style>
