<template>
  <div class="row py-2 gx-3 align-items-center bg-light">
    <div class="col-auto">
      <div class="form-floating">
        <input type="date" class="form-control" id="filterFrom" placeholder="Od" v-model="filterFrom">
        <label class="text-muted" for="filterFrom">Od</label>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-floating">
        <input type="date" class="form-control" id="filterTo" placeholder="Do" v-model="filterTo">
        <label class="text-muted" for="filterTo">Do</label>
      </div>
    </div>
  </div>
  <table class="table table-hover table-borderless">
    <thead>
      <tr>
        <th>Datum přijetí platby</th>
        <th>Variabilní symbol platby</th>
        <th>Příchozí částka</th>
        <th>Zaúčtována/napárována na vyúčtování (VS)</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(payment, idx) in filteredPayments" :key="`payment-${idx}`" class="text-primary">
        <td>{{ payment.dateFormatted }}</td>
        <td>{{ payment.vs }}</td>
        <td>{{ payment.priceFormatted }}</td>
        <td>{{ payment.invoiceId }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {formatDate, formatMoney, parseDateFromString} from '@/helpers';

export default {
  name: "PaymentsTable",
  props: {
    payments: Array,
  },
  data() {
    return {
      filterFrom: null,
      filterTo: null,
    }
  },
  computed: {
    filteredPayments() {
      const filteredPayments = this.payments.filter(p => {
        const paymentDate = new Date(p.date);

        // filtr datumu platby od
        const filterFromDate = parseDateFromString(this.filterFrom);
        if (filterFromDate !== null && filterFromDate > paymentDate) {
          return false;
        }

        // filtr datumu platby do
        const filterToDate = parseDateFromString(this.filterTo);
        if (filterToDate !== null && filterToDate < paymentDate) {
          return false;
        }

        return true;
      });

      return filteredPayments.map(payment => {
        payment.dateFormatted = formatDate(payment.date);
        payment.priceFormatted = formatMoney(payment.sum);
        return payment;
      });
    },
  },
}
</script>

<style scoped>
  table {
    font-size: .9rem;
    border-collapse: separate;
    border-spacing: 0 1rem;
  }

  table td {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 1.6rem .8rem;
  }

  table th {
    font-weight: normal;
    color: #6b6b6b;
    padding: 0 .8rem;
  }

  table td:first-child {
    border-left: 1px solid #d0d0d0;
    border-radius: .6rem 0 0 .6rem;
  }

  table td:last-child {
    border-right: 1px solid #d0d0d0;
    border-radius: 0 .6rem .6rem 0;
  }
</style>