<template>
  <form @submit.prevent="load">
    <div class="input-group">
      <input type="text" placeholder="ID zákazníka" v-model="customerId" class="form-control"/>
      <button type="submit" class="btn btn-primary"><i class="bi bi-forward"></i></button>
    </div>
  </form>
</template>

<script>
import {loadCustomerData} from "@/helpers";

export default {
  inject: ["globalSpinner", "globalFlashMessage", "globalCustomer"],
  data() {
    return {
      customerId: null,
    }
  },
  methods: {
    load() {
      this.globalFlashMessage.data = "";
      this.globalSpinner.active = true;

      // kontrola validity ID zakaznika
      if (isNaN(parseInt(this.customerId))) {
        this.globalFlashMessage.data = "Zadejte ID zákazníka";
        this.globalSpinner.active = false;
        return;
      }

      loadCustomerData(this.customerId)
          .then((customer) => {
            this.globalCustomer.data = customer
          })
          .catch(err => this.globalFlashMessage.data = err)
          .finally(() => this.globalSpinner.active = false);
    }
  },
}
</script>

<style scoped>
</style>
