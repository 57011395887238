import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Billing from '../views/Billing.vue'
import Payments from '../views/Payments.vue'
import Documents from '../views/Documents.vue'
import Account from '../views/Account.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import AuthAction from '../views/AuthAction.vue'
import PasswordSaved from '../views/PasswordSaved.vue'
import ResetPassword from '../views/ResetPassword.vue'
import { auth } from '@/firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vyuctovani',
    name: 'Billing',
    component: Billing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/platby',
    name: 'Payments',
    component: Payments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dokumenty',
    name: 'Documents',
    component: Documents,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ucet',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/prihlaseni',
    name: 'Login',
    component: Login
  },
  {
    path: '/auth-action',
    name: 'AuthAction',
    component: AuthAction
  },
  {
    path: '/heslo-ulozeno',
    name: 'PasswordSaved',
    component: PasswordSaved
  },
  {
    path: '/reset-hesla',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/registrace',
    name: 'Register',
    component: Register
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) {
    next('/prihlaseni');
  } else {
    next();
  }
})

export default router
