import moment from 'moment';
import {passwordStrength} from "check-password-strength";
import {customersCollection, gfcLoadCustomerData} from "@/firebase";

//const snapshotListenerUnsubscribers = [];

const currencyFormatter = new Intl.NumberFormat('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
});

const dataUriToBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString})
}

const formatDate = (date) => moment(date).format("l");
const formatMoney = (money) => currencyFormatter.format(money);
const formatPhone = (phone) => "+" + Number(phone).toLocaleString("cs-CZ");
const isDateValid = (dateString) => moment(dateString).isValid();
const isEmailValid = (email) => {
    const re = /(.+)@(.+){2,}\.(.+){2,}/;
    return re.test(email.toLowerCase());
};
const parseDateFromString = (dateString) => {
    if (isDateValid(dateString)) {
        return new Date(dateString);
    }
    return null;
}

// kvuli moznosti mic vice loginu k jednomu zakaznikovi
const parseCustomerId = (uid) => uid.split('.')[0];

const validatePassword = (password, passwordConfirm) => {
    if (password === '') {
        return {error: "Vyplňte prosím nové heslo"};
    }

    if (password !== passwordConfirm) {
        return {error: "Hesla musí být stejná"};
    }

    const ps = passwordStrength(password);
    if (ps.length < 9) {
        return {error: "Heslo je příliš krátké, musí obsahovat alespoň 9 znaků"};
    }

    if (!ps.contains.includes("number")) {
        return {error: "Heslo musí obsahovat alespoň jednu číslici"};
    }

    if (!ps.contains.includes("uppercase")) {
        return {error: "Heslo musí obsahovat alespoň jedno velké písmeno"};
    }

    if (!ps.contains.includes("lowercase")) {
        return {error: "Heslo musí obsahovat alespoň jedno malé písmeno"};
    }

    return {};
}

const loadCustomerData = (uid) => new Promise((resolve, reject) => {
    if (parseCustomerId(uid) === "zame") {
        resolve(null);
    } else {
        const requestTime = (new Date()).getTime();
        console.debug("Požadavek na data zákazníka z IRIS");
        gfcLoadCustomerData({uid: uid, requestTime: requestTime})
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject("Zákaznická data se nepodařilo načíst, kontaktujte prosím podporu samoobsluhy");
            });
    }
});

const onCustomerDataSnapshot = (user) => new Promise((resolve, reject) => {
    const customerId = parseCustomerId(user.uid);
    customersCollection.doc(customerId)
        .onSnapshot(doc => {
            const customer = doc.data();
            if (customer !== undefined && customer.lastRequestTime !== undefined) {
                resolve(customer)
            }
        }, reject);
});

export {
    dataUriToBlob,
    formatDate,
    formatMoney,
    formatPhone,
    validatePassword,
    isEmailValid,
    loadCustomerData,
    onCustomerDataSnapshot,
    parseDateFromString,
}