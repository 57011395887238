import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/app-check'

let firebaseConfig;

// autokonfigurace SDK neni mozna, pokud chceme nastavit jiny nez vychozi region, proto zatim takto
if (location.hostname === 'portal.nordictelecom.cz' || location.hostname === 'prod-selfcare-313607.web.app') {
    firebaseConfig = {
        apiKey: "AIzaSyBtYhH0MbdY0KB2RvxaMtlE2okBR7o4ZtE",
        authDomain: "prod-selfcare-313607.firebaseapp.com",
        projectId: "prod-selfcare-313607",
        storageBucket: "prod-selfcare-313607.appspot.com",
        messagingSenderId: "270258224732",
        appId: "1:270258224732:web:0d847c37029532704ef099",
        measurementId: "G-ZW0BCDJGZP"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyAGKYrpvGZAvLXvtHn7cc_OAGAb2j9Bsrg",
        authDomain: "poc-selfcare.firebaseapp.com",
        projectId: "poc-selfcare",
        storageBucket: "poc-selfcare.appspot.com",
        messagingSenderId: "883917105669",
        appId: "1:883917105669:web:15bde464d4c63d151a8a6c",
        measurementId: "G-F0CEKTE84C"
    };
}

const app = firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const auth = firebase.auth();
const functions = app.functions("europe-west3"); // kvuli CORS

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
    firestore.useEmulator('localhost', 8084);
    //auth.useEmulator('http://localhost:9099')
    functions.useEmulator("localhost", 5001);
} else {
    // RecaptchaV3 site klic
    const appCheck = firebase.appCheck();
    appCheck.activate('6LcntFYbAAAAAIPKvrNWgjCM5LNrZdRTv80j0G7r');
}

// kolekce
const customersCollection = firestore.collection("/customers");

// funkce
const gfcLoadCustomerData = functions.httpsCallable("loadCustomerData");
const gfcDownloadDoc = functions.httpsCallable("downloadDoc");
const gfcSendResetPasswordEmail = functions.httpsCallable("sendResetPasswordEmail");
const gfcRegister = functions.httpsCallable("register");

export {
    auth,
    customersCollection,
    gfcLoadCustomerData,
    gfcDownloadDoc,
    gfcSendResetPasswordEmail,
    gfcRegister,
}