<template>
  <app-header :showSpinner="spinner.active" :logged-as-zame="access.zame"/>
  <main class="flex-shrink-0">
    <notices :notices="notices" v-if="notices"></notices>
    <flash-message :message="flashMessage.data"></flash-message>
    <router-view/>
  </main>
  <app-footer/>
</template>

<script>
import {onBeforeMount} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import {auth, gfcDownloadDoc} from '@/firebase'
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import {saveAs} from 'file-saver';
import {useStore} from "vuex/dist/vuex.mjs";
import FlashMessage from "@/components/FlashMessage";
import Notices from "@/components/Notices";
import {dataUriToBlob, onCustomerDataSnapshot} from "@/helpers";

export default {
  components: {FlashMessage, AppFooter, AppHeader, Notices},
  data: () => ({
    spinner: {
      active: false,
    },
    flashMessage: {
      data: "",
    },
    customer: {
      data: null
    },
    access: {
      zame: false,
    }
  }),
  onIdle() {
    if (auth.currentUser !== null) {
      auth.signOut().then(() => {
        this.flashMessage.data = "Došlo k automatickému odhlášení po delší nečinnosti";
      });
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    onBeforeMount(() => {
      auth.onAuthStateChanged((user) => {
        if (route.path === '/auth-action') {
          return;
        }

        if (!user && route.path !== '/reset-hesla' && route.path !== '/registrace') {
          store.commit('setAuthorization', false);
          store.commit('setUsername', null);
          router.replace('/prihlaseni');
        } else if (route.path === '/prihlaseni') {
          store.commit('setAuthorization', true);
          store.commit('setUsername', user.displayName);
          router.replace('/');
        } else if (route.path === '/heslo-ulozeno') {
          store.commit('setAuthorization', true);
          store.commit('setUsername', user.displayName);
        }
      })
    });
  },
  created() {
    auth.onAuthStateChanged((user) => {
      this.access.zame = false;

      // vymazani dat prihlaseneho zk.
      if (!user) {
        this.customer.data = null;
      } else {
        user.getIdTokenResult()
            .then((idTokenResult) => {
              if (idTokenResult.claims.zame) {
                this.access.zame = true;
              }
            })
            .catch((err) => {
              this.flashMessage.data = err;
            });
      }
    });
  },
  computed: {
    notices() {
      if (this.customer.data === null) {
        return [];
      }

      return this.customer.data.notices;
    }
  },
  provide() {
    return {
      globalSpinner: this.spinner,
      globalFlashMessage: this.flashMessage,
      globalCustomer: this.customer,
      loadCustomerSnapshot: () => {
        if (this.customer.data === null && auth.currentUser !== null) {
          onCustomerDataSnapshot(auth.currentUser).then((customer) => {
            this.customer.data = customer;
          });
        }
      },
      loadDocumentPdf: (query, outputFilename) => {
        this.spinner.active = true;
        gfcDownloadDoc(query)
            .then((response) => {
              if (response.data.error) { // odchyceni chyby pri stahovani dokumentu
                this.flashMessage.data = response.data.error;
              } else {
                const pdfBlob = dataUriToBlob(`data:application/pdf;base64,${response.data}`);
                saveAs(pdfBlob, outputFilename);
              }
            })
            .catch(err => this.flashMessage.data = err)
            .finally(() => this.spinner.active = false);
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/scss/custom.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';

#app {
  font-family: 'Graphik', sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.narrow-container {
  max-width: 580px;
  text-align: center;
}

.toggle-password-icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  bottom: 10px;
  right: 10px;
  margin-top: 0;
  font-size: 1.3rem;
}

main {
  min-height: 60vh;
}

@font-face {
  font-family: 'Graphik';
  src: url(./fonts/Graphik-Light.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url(./fonts/Graphik-Semibold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikWide';
  src: url(./fonts/GraphikWide-Semibold.otf) format("opentype");
  font-weight: 600;
  font-style: normal;
}

</style>
